const OrderList = () => import('@/views/account/Order/OrderList.vue')

export default [
    {
        path: '/',
        component: OrderList,
        name: 'orders',
        meta: {
            keepAlive: true,
            requiresAuth: true,
            layout: 'AccountLayoutWithBreadcrumbs',
            breadcrumbs: {
                name: 'Projects',
                additionalClass: 'bc-horizontal'
            }
        }
    }
]
