// import Cow from 'mdi-vue/Cow'
// import CreditCard from 'mdi-vue/CreditCard'

// import WrenchClock from 'mdi-vue/WrenchClock'

const TYPES = {
    success: 'success',
    primary: 'primary',
    warning: 'warning',
    danger: 'danger'
}

export const HeaderInfoList = [
    // {
    //     type: TYPES.success,
    //     // иконка будет отображатся слева от текста !ПРИНИМАЕТ ТОЛЬКО КОМПОНЕНТ! ----- НЕ ОБЯЗАТЕЛЬНО
    //     customIcon: Cow,
    //     // Текст или html (!REQUIRED!)
    //     text: '#StandWithUkraine.',
    //     // Уникальный ID для сохранения в cookies (!REQUIRED!)
    //     id: '1'
    // },
    // {
    //     type: TYPES.primary,
    //     // иконка будет отображатся слева от текста !ПРИНИМАЕТ ТОЛЬКО КОМПОНЕНТ! ----- НЕ ОБЯЗАТЕЛЬНО
    //     customIcon: '',
    //     // Текст или html (!REQUIRED!)
    //     text: '#StandWithUkraine.',
    //     // Уникальный ID для сохранения в cookies (!REQUIRED!)
    //     id: '2'
    // },
    // {
    //     type: TYPES.primary,
    //     customIcon: '',
    //     text: 'You are now able to upload files that are up to 100 MB in size.',
    //     id: '3'
    // },
    // {
    //     type: TYPES.danger,
    //     customIcon: CreditCard,
    //     text: 'We are sorry that we can no longer offer Paypal as a payment gateway. You can still pay with VISA and Mastercard directly on our website and we are working to bring more payment options.',
    //     id: '4'
    // },
    // {
    //     type: TYPES.danger,
    //     customIcon: '',
    //     text: 'We are upgrading our systems! Some functionality may be temporarily unavailable. Please report any problems to our support team.',
    //     id: '7'
    // }
    // {
    //     type: TYPES.success,
    //     customIcon: '',
    //     text: 'The upgrade is complete and all our systems are running.',
    //     id: '8'
    // },
    // {
    //     type: TYPES.success,
    //     customIcon: '',
    //     text: 'We have a new payment gateway! It should be more reliable and offer more payment options. Please contact our support, in case of any problems.',
    //     id: '9'
    // },
    // {
    //     type: TYPES.success,
    //     customIcon: '',
    //     text: 'We have fixed the issue with credits. Now your account balance (credits) will be applied to your purchase automatically! We are sorry about the inconvenience caused.',
    //     id: '10'
    // },
    {
        type: TYPES.success,
        customIcon: '',
        text: 'Refer friends and earn 25% on every purchase. Then, cash it out to your bank account or card! <a href="/affiliate">Learn more</a>',
        id: '11'
    }
]
