const Notifications = () => import('@/views/account/Notifications.vue')

export default [
    {
        path: '/notifications',
        component: Notifications,
        name: 'notifications',
        meta: {
            requiresAuth: true,
            layout: 'AccountLayoutWithoutBreadcrumbs',
            breadcrumbs: {
                bgWrapper: true,
                name: 'Notifications',
                additionalClass: 'bc-horizontal bc-notifications',
                excerpt: ''
            }
        }
    }
]
