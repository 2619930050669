const Billing = () => import('@/views/account/BillingPage.vue')
const MyBatchDetails = () => import('@/views/account/MyBatchDetails.vue')

export default [
    {
        path: '/billing',
        component: Billing,
        name: 'billing',
        meta: {
            requiresAuth: true,
            layout: 'AccountLayoutWithBreadcrumbs',
            breadcrumbs: {
                name: 'Billing',
                additionalClass: 'bc-horizontal',
                excerpt: 'We call each payment a \'batch\' because it can include more than one project. You can enter more than one project in the shopping cart during our ordering process.'
            }
        }
    },
    {
        path: '/billing/:id',
        component: MyBatchDetails,
        name: 'batch',
        meta: {
            requiresAuth: true,
            keepAlive: true,
            layout: 'AccountLayoutWithBreadcrumbs',
            breadcrumbs: {
                name: 'Billing Details',
                additionalClass: 'bc-horizontal bc-horizontal__details',
                parent_route_names: ['billing']
            }
        }
    }
]
