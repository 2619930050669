const Feedback = () => import('@/views/FeedbackGuest/FeedbackPage.vue')
const SuccessFeedback = () => import('@/views/FeedbackGuest/SuccessFeedback.vue')

export default [
    {
        path: '/feedback/create',
        component: Feedback,
        name: 'create_feedback',
        meta: {
            layout: 'AccountLayoutWithBreadcrumbs',
            breadcrumbs: {
                name: 'Review our service',
                additionalClass: 'bc-wrapper bc-horizontal ',
                excerpt: 'Please review the quality of service you received from our company. Was the service good? Was the project done well by the writer? Your feedback is truly appreciated and will help us to maintain the quality of our service on a higher level.'
            }
        }
    },
    {
        path: '/feedback/success',
        component: SuccessFeedback,
        name: 'success_rate_writer',
        meta: {
            layout: 'EmptyLayout'
        }
    }
]
